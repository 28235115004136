import * as types from "../actionTypes";
import axios from "axios";
import courseGroupAndJurisdictionRelation from '../../data/courseGroupAndJurisdictionRelation.json';
import APIConstants from "../../utils/APIConstants";
import partners from '../../data/partners.json';
import {CEStdCourseFilter} from '../../data/ce-std-jsons/cestdCourseFilter.js';
// import partnerRelation from '../../data/partnerRelation.json';

export const fetchCoursesRequest = () => {
    return {
        type: types.FETCH_COURSES_REQUEST
    }
}

export const fetchCoursesSuccess = (courses, cestdrack, suggestion) => {
    return {
        type: types.FETCH_COURSES_SUCCESS,
        payload: courses,
        stdrack : cestdrack,
        suggestion : suggestion
    }
}

export const fetchAllShopifyCoursesSuccess = courses => {
    return {
        type: types.FETCH_ALL_SHOPIFY_COURSES_SUCCESS,
        payload: courses
    }
}

export const fetchCoursesFailure = () => {
    return {
        type: types.FETCH_COURSES_FAILURE
    }
}


let allShopifyCourses
let allRechargeCourses

export const fetchCourses = (partnerType = "shop", partnerRelation) => {
    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    // const shopifyReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.SHOPIFY_ALL_PRODUCTS_NEW + partnerId + '&env=stg'}`);
    const shopifyReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.SHOPIFY_ALL_PRODUCTS_NEW + partnerId}`);
    // const rechargeReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.RECHARGE_ALL_PRODUCTS_NEW + partnerId + '&env=stg'}`);
    const rechargeReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.RECHARGE_ALL_PRODUCTS_NEW + partnerId}`);

    return (dispatch) => {
        dispatch(fetchCoursesRequest())
        axios.all([shopifyReq, rechargeReq])
            .then(res => {
                dispatch(fetchAllShopifyCoursesSuccess(res[0].data.data));
                allShopifyCourses = cleanUpShopifyResponse(res[0].data.data)
                allRechargeCourses = cleanUpRechargeResponse(res[1].data.data)
                const filteredCourses = [...filterAndMergeShopifyCourseObjByPartner(partnerType, partnerRelation), ...filterAndMergeRechargeCourseObjByPartner(partnerType, partnerRelation)];
                
                 
                let cestdFilter = new CEStdCourseFilter();
                let courseStack = cestdFilter.getCourseStack(filteredCourses)

                dispatch(fetchCoursesSuccess(filteredCourses, courseStack.rack, courseStack.suggested))
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchCoursesFailure())
            })
    }
}


const filterAndMergeShopifyCourseObjByPartner = (partnerType, partnerRelation) => {

    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    let partnerRelFilteredObj = partnerRelation.filter(item => item.partnerId == partnerId);
    // debugger
    let intermediateMerge = [];
    // merge with api
    partnerRelFilteredObj.forEach(item => {
        if (item.courseType === "shopify") {
            let foundIndex = allShopifyCourses.findIndex(innerItem => item.shopifyHandle === innerItem.handle);
            // console.log("foundIndex", foundIndex)
            if (foundIndex !== -1)
                intermediateMerge.push({
                    ...item,
                    ...allShopifyCourses[foundIndex],
                    "isMember": allShopifyCourses[foundIndex] != undefined ? checkIfMember(allShopifyCourses[foundIndex].tags) : false
                })

        }
    })

    // console.log('intermediateMerge==Shopify=>', JSON.stringify(intermediateMerge))

    let intermediateMerge2 = [];

    intermediateMerge.forEach(item => {
        const foundIndex = allRechargeCourses.findIndex(innerItem => item.handle === innerItem.handle);
        if (foundIndex !== -1)
            intermediateMerge2.push({ ...item, ...allRechargeCourses[foundIndex] })
        // else 
        // console.log("NOT FOUND ==>", item.handle)
    })


    let finalMerge = [];
    // merge with course rel
    intermediateMerge2.forEach(item => {
        let foundIndex = courseGroupAndJurisdictionRelation.findIndex(innerItem => item.courseRelId === innerItem.id && innerItem.collection !== 2)
        if (foundIndex !== -1)
            finalMerge.push({ ...item, ...courseGroupAndJurisdictionRelation[foundIndex] })
    })

    // console.log('finalMerge==Shopify=>', JSON.stringify(finalMerge))
    return finalMerge
}


const filterAndMergeRechargeCourseObjByPartner = (partnerType, partnerRelation) => {

    // console.log('allRechargeCourses', JSON.stringify(allRechargeCourses))

    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    let partnerRelFilteredObj = partnerRelation.filter(item => item.partnerId == partnerId);
    let intermediateMerge = [];
    // merge partnerRelation with recharge api
    partnerRelFilteredObj.forEach(item => {
        if (item.courseType === "recharge") {
            // console.log("item", item)
            let foundIndex = allRechargeCourses.findIndex(innerItem => item.rechargeHandle === innerItem.handle);
            if (foundIndex !== -1)
                intermediateMerge.push({ ...item, ...allRechargeCourses[foundIndex], rechargeCourseFound: true })
            else
                intermediateMerge.push({ ...item, rechargeCourseFound: false })
        }
        else{
            let foundIndex = allRechargeCourses.findIndex(innerItem => item.shopifyHandle === innerItem.handle && item.shopifyHandle.indexOf("non-u-s-practitioners") !== -1);
            if (foundIndex !== -1)
                intermediateMerge.push({ ...item, ...allRechargeCourses[foundIndex], rechargeCourseFound: true })
           

        }
    })
    // console.log("intermediateMerge", intermediateMerge)
    let intermediateMerge2 = [];
    // merge with course rel
    intermediateMerge.forEach(item => {
        const foundIndex = courseGroupAndJurisdictionRelation.findIndex(innerItem => item.courseRelId === innerItem.id);
        if (foundIndex !== -1)
            intermediateMerge2.push({ ...item, ...courseGroupAndJurisdictionRelation[foundIndex] })
    })

    let finalMerge = [];

    // get recharge subscription price from shopify
    intermediateMerge2.forEach(item => {

        const foundIndexForSubsPrice = allShopifyCourses.findIndex(innerItem => item.rechargeHandle === innerItem.handle);
        // console.log("foundIndexForSubsPrice", allShopifyCourses[foundIndexForSubsPrice])
        // const foundIndexForSubsPriceYearly = allShopifyCourses.findIndex(innerItem => item.shopifyOneTimeHandle === innerItem.handle);

        const foundIndexForOneTimePrice = allShopifyCourses.findIndex(innerItem => {
            return item.shopifyOneTimeHandle === innerItem.handle
        });

        //  "optionTitle": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? getValueFromOption(allShopifyCourses[foundIndexForOneTimePrice].options, "title") : '',
        if(item.category === undefined && item.category !== 'non-us'){
            finalMerge.push({
                ...item,
                // "shekar":"shantanu",
                "rechargeSubscriptionPrice": allShopifyCourses[foundIndexForSubsPrice] != undefined ? allShopifyCourses[foundIndexForSubsPrice].variants[0].price : '',
                "shopifyOneTimePrice": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? allShopifyCourses[foundIndexForOneTimePrice].variants[0].price : '',
                "shopifyThumbnail": item.rechargeCourseFound === false ? allShopifyCourses[foundIndexForOneTimePrice].image.src : '',
                "CPD": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? getCPDvalue(allShopifyCourses[foundIndexForOneTimePrice].options) : '',
                "isMember": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? checkIfMember(allShopifyCourses[foundIndexForOneTimePrice].tags) : false,
                "variantIdYearly": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? allShopifyCourses[foundIndexForOneTimePrice].variants[0].id : null,
                "variantIdMonthly": allShopifyCourses[foundIndexForSubsPrice] != undefined ? allShopifyCourses[foundIndexForSubsPrice].variants[0].id : null,
                "productIdYearly": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? allShopifyCourses[foundIndexForOneTimePrice].variants[0].product_id : null,
                "productIdMonthly": allShopifyCourses[foundIndexForSubsPrice] != undefined ? allShopifyCourses[foundIndexForSubsPrice].variants[0].product_id : null,
                "optionTitle": allShopifyCourses[foundIndexForOneTimePrice] != undefined ? getValueFromOption(allShopifyCourses[foundIndexForOneTimePrice].options, "title") : ''
            })
        }
        else{
            let foundIndex = allShopifyCourses.findIndex(innerItem => item.shopifyHandle === innerItem.handle);
            // console.log("foundIndex", foundIndex)
            if (foundIndex !== -1)
            finalMerge.push({
                    ...item,
                    ...allShopifyCourses[foundIndex],
                    "isMember": allShopifyCourses[foundIndex] != undefined ? checkIfMember(allShopifyCourses[foundIndex].tags) : false
                })
        }
        
    })

    // console.log('finalMerge==Recharge=>', JSON.stringify(finalMerge))
    return finalMerge
}

const getCPDvalue = obj => {
    let foundIndex = obj.findIndex(item => item.name === "CPD")
    if (foundIndex != -1)
        return obj[foundIndex].values[0]
    else
        return ''
}

const getValueFromOption = (obj, key) => {
    // console.log("getValueFromOption", obj)
    let titleObj = obj.filter(item => item.name === key)
    if (titleObj.length > 0)
        return titleObj[0].values[0]
}

// helpers
const cleanUpShopifyResponse = (allShopifyCourses) => {
    let tempObj = []
    allShopifyCourses.forEach(item => {
        deleteProps(item, ["created_at"])
        tempObj.push(item)
    })
    return allShopifyCourses
}

const cleanUpRechargeResponse = (allRechargeCourses) => {
    let tempObj = []
    allRechargeCourses.forEach(item => {
        deleteProps(item, ["collection_id", "discount_amount", "created_at", "discount_type"])
        tempObj.push(item)
    })
    return allRechargeCourses
}

const checkIfMember = tagsString => {
    // console.log("tagsString", tagsString)
    if (tagsString.split(',').indexOf(" member") != -1)
        return true
    else
        return false
}

function deleteProps(obj, prop) {
    for (const p of prop) {
        (p in obj) && (delete obj[p]);
    }
}