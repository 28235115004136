// ============ CMS DEVELOPMENT/STAGING ENV =========================
// export const API_URL = process.env.NODE_ENV == "development" ? "http://localhost:1337" : "http://tradecompliance.co:1337";
// export const ROBOT_META = "noindex,nofollow";

// ============ CMS LOCAL ENV =========================
// export const API_URL = "http://localhost:1338";
// export const ROBOT_META = "noindex,nofollow";


// SHOP DEV
// export const API_URL = "//appproxy.contentenablers.com/storefront2020/index.php/shopify/proxy/strapi?input=";


// SHOP PROD
export const API_URL = "//apps.contentenablers.com/storefront2020/index.php/shopify/proxy/strapi?input=";
export const ROBOT_META = "INDEX, FOLLOW";
  
// ===========================================================================
// asset urls
export const S3_URL = "https://s3.amazonaws.com/thumbs.contentenablers.com/website/imgs/";
export const CF_URL = "https://assets.contentenablers.com/website/imgs/";
export const RECAPTCHA_SITE_KEY = "6LfmJPYUAAAAAFhV33gyhTX4l_9m_kEGhIZgdDWs";

// shopify
export const SHOPIFY_STOREFRONT_ACCESS_TOKEN = "bbb358100590ea6b33bf2d93fe5bb557";
export const SHOPIFY_DOMAIN = "content-enablers.myshopify.com";